<template>
    <div>
        <div v-if="!isLoading" class="wizard-container">
          <code v-if="isDevelopment && false" style="float: left; position: absolute; left: calc(50% - (650px / 2)); bottom: -2.65rem; padding: .5rem; display: flex; z-index: 10000; max-width: 650px;">
          Hash: {{ currentHash }}
          </code>
          <div class="wizard-header">
            <div class="bar"></div>
            <div class="slide">
              <div class="circle" :is-active="slide === 0" :is-completed="slide > 0">1</div>
              <span>INTRODUCTION</span>
            </div>
            <div class="slide" v-if="isOnline && false">
              <div class="circle" :is-active="slide === 1" :is-completed="slide > 1">2</div>
              <span>BANK INFORMATION</span>
            </div>
            <div class="slide" v-if="isOnline && false">
              <div class="circle" :is-active="slide === 2" :is-completed="slide > 2">3</div>
              <span>CONTACTS</span>
            </div>
            <div class="slide" v-if="isOffline">
              <div class="circle" :is-active="slide === 1" :is-completed="slide > 1">2</div>
              <span>DOWNLOAD/UPLOAD</span>
            </div>
            <div class="slide" v-if="isOnline">
              <div class="circle" :is-active="slide === 1" :is-completed="slide > 1">2</div>
              <span>BASIC INFORMATION</span>
            </div>
            <div class="slide">
              <div class="circle" :is-active="slide === 2" :is-completed="slide > 2">3</div>
              <span>REVIEW</span>
            </div>
            <div class="slide">
              <div class="circle" :is-active="slide === 3" :is-completed="slide > 3">4</div>
              <span>FINISH</span>
            </div>
          </div>
          <div class="wizard-actions" v-if="!isLastSlide()">
            <button v-if="slide > 0 && slide < 4" @click="slide--" style="position: absolute; left: 0;">
              Previous Slide
            </button>
            <button
              :disabled="(!currentSlideHasReviewed && slide !== 0 && !isOffline) || isLocked" class="is-accent"
              v-if="slide < numSlides"
              @click="progressWizard"
            >
              Next Slide
            </button>
            <button :disabled="!hasChanged" class="is-default" v-if="slide < numSlides - 1 && slide > 0 && isOnline" @click="saveForLater">
              Save For Later
            </button>
          </div>
          <div class="wizard-content" style="max-height: calc(100vh - 150px); height: 100%; overflow: scroll;">
              <IntroductionSlide v-if="slide === 0" @completed="completed" :reportType="reportType" :questionnaire="questionnaire" @process-type-changed="onReportTypeChange"/>
              <BankInformationSlide v-if="isOnline && slide === 1 && false" @completed="completed" :questionnaire="questionnaire" @has-reviewed="$event => currentSlideHasReviewed = $event" :hash="currentHash"/>
              <ContactsSlide v-if="isOnline && slide === 2 && false" @completed="completed" :questionnaire="questionnaire" @reset="reset" @has-reviewed="$event => currentSlideHasReviewed = $event" :hash="$sha256(questionnaire.contacts)" />
              <BasicInformationSlide v-if="isOnline && slide === 1" @completed="completed" :participantsHash="participantsHash" :questionnaire="questionnaire" @reset="reset"  @import-participants="importParticipants"  @toggleLock="logToggleLock" @validate-data="validateParticipants"  @has-reviewed="$event => currentSlideHasReviewed = $event" :hash="$sha256(questionnaire.participants)"/>
              <ReviewSlide v-if="(isOnline && slide === 2) || (isOffline && slide === 2)" @completed="completed" :questionnaire="questionnaire" @has-reviewed="$event => currentSlideHasReviewed = $event" :hash="currentHash" @toggleLock="logToggleLock"/>
              <ThankYouSlide v-if="slide === 3 && questionnaire" @finish-annual-questionnaire="e => $emit('finish-annual-questionnaire', e)"  :hash="currentHash" />
              <UploadSlide v-if="isOffline && slide === 1" :hasUploaded="hasUploaded" :upload="upload" :download="download" @completed="completed" :questionnaire="questionnaire" @has-reviewed="$event => currentSlideHasReviewed = $event" @upload="upload" :hash="currentHash" @toggleLock="logToggleLock"/>
          </div>
          <span v-for="error in formErrors" :key="error">
            <p style="color: red">{{ error }}</p>
          </span>
        </div>
        <div v-if="isLoading">
          <Loading />
        </div>
    </div>
</template>

<script>
import * as XLSX from 'xlsx'

import IntroductionSlide from './Slides/IntroductionSlide.vue'
import BankInformationSlide from './Slides/BankInformationSlide.vue'
import ContactsSlide from './Slides/ContactsSlide.vue'
import BasicInformationSlide from './Slides/BasicInformationSlide.vue'
import ReviewSlide from './Slides/ReviewSlide.vue'
import ThankYouSlide from './Slides/ThankYouSlide.vue'
import UploadSlide from './Slides/UploadSlide.vue'
import Loading from '../../Loading'

import moment from 'moment'

export default {
  components: {
    IntroductionSlide,
    BankInformationSlide,
    ContactsSlide,
    BasicInformationSlide,
    ReviewSlide,
    ThankYouSlide,
    UploadSlide,
    Loading
  },
  props: {
    questionnaire: {
      type: Object,
      required: true
    },
    participantsHash: {
      type: String,
      required: true
    },
    hasChanged: {
      type: Boolean,
      required: true
    },
    currentHash: {
      type: String,
      required: true
    },
    reportType: {
      type: String,
      required: true,
      default: 'online'
    }
  },
  data: function () {
    return {
      isLoading: false,
      slide: 0,
      prevSlide: 0,
      formErrors: [],
      isLocked: false,
      hasUploaded: false,
      currentSlideHasReviewed: false,
      isDevelopment: process.env.NODE_ENV === 'development',
      slides: {
        IntroductionSlide: null,
        BankInformationSlide: null,
        ContactsSlide: null,
        BasicInformationSlide: null,
        ReviewSlide: null,
        FinishSlide: null
      }
    }
  },
  watch: {
    slide (newVal, oldVal) {
      window.localStorage.setItem(`{${this.questionnaire.questionnaireId}}annual-questionnaire-slide`, newVal)
      if (newVal > oldVal) {
        this.$emit('next-slide', newVal)
        this.slides[Object.keys(this.slides)[oldVal]] = {} || null
      }

      if (newVal < oldVal) {
        this.$emit('previous-slide', newVal)
      }

      this.isLocked = false // reset if we navigate back

      this.currentSlideHasReviewed = false
    },
    hasChanged (newVal, oldVal) {
      this.$forceUpdate()
    },
    questionnaire: {
      handler (newVal, oldVal) {
        this.slideSetup()
        this.$forceUpdate()
      },
      deep: true
    },
    reportType (newVal, oldVal) {
      if (newVal === 'Online') {
        this.slides = {
          IntroductionSlide: null,
          BankInformationSlide: null,
          ContactsSlide: null,
          BasicInformationSlide: null,
          ReviewSlide: null,
          FinishSlide: null
        }
      } else {
        this.slides = {
          IntroductionSlide: null,
          UploadSlide: null,
          ReviewSlide: null,
          FinishSlide: null
        }
      }
      this.$forceUpdate()
    }
  },
  computed: {
    numSlides () {
      return Object.keys(this.slides).length
    },
    isOnline () {
      return this.reportType === 'online'
    },
    isOffline () {
      return this.reportType === 'offline'
    }
  },
  mounted () {
    this.slideSetup()

    this.$emit('wizard-loaded', this)
    window.requestAnimationFrame(async () => {
      document.getElementById('content-area-full').style.backgroundColor = '#ebebeb'
    })
  },
  methods: {
    validateParticipants () {
      // this.questionnaire.participants.forEach(x => {
      //   console.log(x)
      // })
    },
    logToggleLock (event) {
      this.isLocked = event
    },
    isLastSlide () {
      if (this.reportType === 'online') {
        return this.slide === 3
      } else {
        return this.slide === 4
      }
    },
    slideSetup () {
      this.slide = parseInt(window.localStorage.getItem(`{${this.questionnaire.questionnaireId}}annual-questionnaire-slide`)) || 0

      if (this.reportType === 'Online') {
        this.slides = {
          IntroductionSlide: null,
          BankInformationSlide: null,
          ContactsSlide: null,
          BasicInformationSlide: null,
          ReviewSlide: null,
          FinishSlide: null
        }
      } else {
        this.slides = {
          IntroductionSlide: null,
          UploadSlide: null,
          ReviewSlide: null,
          FinishSlide: null
        }
      }
    },
    handleDownloadError (_) {
      // console.log(error)
    },
    async upload () {
      this.isLoading = true

      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.accept = '.xlsx'
      fileInput.click()

      fileInput.addEventListener('change', (event) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.onload = (event) => {
          this.formErrors = []
          const data = event.target.result
          let workbook = XLSX.read(data, { type: 'binary' })

          if (workbook.SheetNames.length > 1) {
            this.isLoading = false
            this.isLocked = true

            this.formErrors.push('Invalid number of sheets. Please use only one sheet.')
            return
          }

          if (workbook.SheetNames.find(sheetName => sheetName !== 'Annual Questionnaire')) {
            this.isLoading = false
            this.formErrors.push('Invalid sheet name. Please use "Annual Questionnaire" as the sheet name.')
            this.isLocked = true
            return
          }

          let sheet = workbook.Sheets['Annual Questionnaire']

          // Bank Contact Information Section
          let legalAddress = sheet['N29'] ? sheet['N29'].v : null
          let legalAddressCityStateZip = sheet['N31'] ? sheet['N31'].v : null
          let mailingAddress = sheet['AL29'] ? sheet['AL29'].v : null
          let mailingAddressCityStateZip = sheet['AL31'] ? sheet['AL31'].v : null
          let mainPhone = sheet['BH29'] ? sheet['BH29'].v : null
          let mainFax = sheet['BH31'] ? sheet['BH31'].v : null

          // Bank Contacts
          let contacts = []
          let primaryContactId = sheet['F37'] ? sheet['F37'].v : null
          let primaryContactName = sheet['N37'] ? sheet['N37'].v : null
          let primaryContactTitle = sheet['AB37'] ? sheet['AB37'].v : null
          let primaryContactPhone = sheet['AO37'] ? sheet['AO37'].v : null
          let primaryContactEmail = sheet['BA37'] ? sheet['BA37'].v : null

          let secondaryContactId = sheet['F39'] ? sheet['F39'].v : null
          let secondaryContactName = sheet['N39'] ? sheet['N39'].v : null
          let secondaryContactTitle = sheet['AB39'] ? sheet['AB39'].v : null
          let secondaryContactPhone = sheet['AO39'] ? sheet['AO39'].v : null
          let secondaryContactEmail = sheet['BA39'] ? sheet['BA39'].v : null

          let tertiaryContactId = sheet['F41'] ? sheet['F41'].v : null
          let tertiaryContactName = sheet['N41'] ? sheet['N41'].v : null
          let tertiaryContactTitle = sheet['AB41'] ? sheet['AB41'].v : null
          let tertiaryContactPhone = sheet['AO41'] ? sheet['AO41'].v : null
          let tertiaryContactEmail = sheet['BA41'] ? sheet['BA41'].v : null

          if (primaryContactName) {
            contacts.push({
              contactId: primaryContactId,
              name: primaryContactName,
              title: primaryContactTitle,
              phone: primaryContactPhone,
              email: primaryContactEmail
            })
          }

          if (secondaryContactName) {
            contacts.push({
              contactId: secondaryContactId,
              name: secondaryContactName,
              title: secondaryContactTitle,
              phone: secondaryContactPhone,
              email: secondaryContactEmail
            })
          }

          if (tertiaryContactName) {
            contacts.push({
              contactId: tertiaryContactId,
              name: tertiaryContactName,
              title: tertiaryContactTitle,
              phone: tertiaryContactPhone,
              email: tertiaryContactEmail
            })
          }

          let participantsStartRow = 47
          let participantCount = parseInt(sheet['F2'].v)
          let participantList = []

          for (let i = 0; i < participantCount; i++) {
            let name = sheet['G' + participantsStartRow] ? sheet['G' + participantsStartRow].v : ''
            let firstName = name.split(' ')[0]
            let lastName = name.split(' ').slice(1).join(' ')

            let participant = {
              participantId: sheet['F' + participantsStartRow] ? sheet['F' + participantsStartRow].v : '',
              firstName: firstName,
              lastName: lastName,
              previousTitle: sheet['O' + participantsStartRow] ? sheet['O' + participantsStartRow].v : '',
              newTitle: sheet['W' + participantsStartRow] ? sheet['W' + participantsStartRow].v : '',
              terminationDate: this.excelSerialDateToDate(sheet['AE' + participantsStartRow] ? sheet['AE' + participantsStartRow].v : ''),
              terminationReason: sheet['AK' + participantsStartRow] ? sheet['AK' + participantsStartRow].v : '',
              salary: sheet['AR' + participantsStartRow] ? sheet['AR' + participantsStartRow].v : '',
              bonus: sheet['AZ' + participantsStartRow] ? sheet['AZ' + participantsStartRow].v : '',
              otherCompensation: sheet['BH' + participantsStartRow] ? sheet['BH' + participantsStartRow].v : '',
              isDeleted: 0,
              policyKey: null,
              createdAt: new Date(),
              updatedAt: new Date(),
              isBCCDeleted: 0,
              questionnaireId: this.questionnaire.questionnaireId,
              hasfirstNameerror: false,
              haslastNameerror: false,
              haspreviousTitleerror: false,
              hassalaryerror: false,
              hasTerminationDateerror: false,
              hasTerminationReasonerror: false
            }

            // if bonus, salary, or other compensation is string then strip to number
            if (typeof participant.bonus === 'string') {
              participant.bonus = parseFloat(participant.bonus.replace(/[^0-9.-]+/g, ''))
            }

            if (typeof participant.salary === 'string') {
              participant.salary = parseFloat(participant.salary.replace(/[^0-9.-]+/g, ''))
            }

            if (typeof participant.otherCompensation === 'string') {
              participant.otherCompensation = parseFloat(participant.otherCompensation.replace(/[^0-9.-]+/g, ''))
            }

            if (participant.isDeleted === null) {
              participant.isDeleted = 0
            }

            if (participant.isBCCDeleted === null) {
              participant.isBCCDeleted = 0
            }

            const validDateFormats = ['YYYY-MM-DD', 'MM/DD/YYYY']

            if (participant.terminationDate && !moment(participant.terminationDate, validDateFormats, true).isValid()) {
              participant.terminationDate = null

              this.formErrors.push('Invalid date format for ' + participant.firstName + ' ' + participant.lastName + 's termination date. Please use YYYY-MM-DD format.')
            }

            participantList.push(participant)
            participantsStartRow++
          }

          // let completedByRow = participantsStartRow + participantCount + 4

          // let completedBy = sheet['O' + completedByRow.toString()] ? sheet['O' + completedByRow.toString()].v : null
          // let completedDate = sheet['AM' + completedByRow.toString()] ? sheet['AM' + completedByRow.toString()].v : null

          // FIll out questionnaire object

          if (legalAddressCityStateZip) {
            // Get parts from city, st zip format
            let legalAddressParts = legalAddressCityStateZip.split(',')
            let legalAddressZip = legalAddressParts[1].trim().split(' ')[1]
            let legalAddressCity = legalAddressParts[0].trim()
            let legalAddressState = legalAddressParts[1].trim().split(' ')[0]

            this.questionnaire.clientAddress = legalAddress
            this.questionnaire.clientAddressCity = legalAddressCity
            this.questionnaire.clientAddressState = legalAddressState
            this.questionnaire.clientAddressPostal = legalAddressZip
          }

          if (mailingAddressCityStateZip) {
            // Get parts from city, st zip format
            let mailingAddressParts = mailingAddressCityStateZip.split(',')
            let mailingAddressZip = mailingAddressParts[1].trim().split(' ')[1]
            let mailingAddressCity = mailingAddressParts[0].trim()
            let mailingAddressState = mailingAddressParts[1].trim().split(' ')[0]

            this.questionnaire.clientMailingAddress = mailingAddress
            this.questionnaire.clientMailingAddressCity = mailingAddressCity
            this.questionnaire.clientMailingAddressState = mailingAddressState
            this.questionnaire.clientMailingAddressPostal = mailingAddressZip
          }

          this.questionnaire.clientPhone = mainPhone || this.questionnaire.clientPhone
          this.questionnaire.clientFax = mainFax || this.questionnaire.clientFax

          this.questionnaire.contacts = contacts.length > 0 ? contacts : this.questionnaire.contacts
          this.questionnaire.participants = participantList
          if (this.formErrors.length > 0) {
            this.logToggleLock(true)
          } else {
            this.logToggleLock(false)
            this.hasUploaded = true
          }

          this.isLoading = false
        }

        reader.readAsBinaryString(file)
      })
    },
    excelSerialDateToDate (serialDate) {
      if (serialDate === null || serialDate === undefined || serialDate === '') {
        return null
      }

      if (typeof serialDate === 'string') {
        return moment(serialDate).format('MM/DD/YYYY')
      }

      const excelEpoch = new Date(1899, 11, 31) // December 31, 1899
      const excelEpochAsUnixTimestamp = excelEpoch.getTime()
      const missingLeapYearDay = (serialDate > 59) ? 1 : 0
      const daysToMilliseconds = 86400000
      const serialDateAdjusted = serialDate - missingLeapYearDay
      const date = new Date(excelEpochAsUnixTimestamp + (serialDateAdjusted * daysToMilliseconds))
      return moment(date).format('YYYY-MM-DD')
    },
    async download () {
      this.isLoading = true
      try {
        const response = await this.axios.get('annual-questionnaire/' + this.questionnaire.questionnaireId + '/download', { responseType: 'blob' })

        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

        // Create a temporary anchor element
        const a = document.createElement('a')

        // Use URL.createObjectURL to get a URL for the blob
        a.href = URL.createObjectURL(blob)
        a.download = response.headers['content-disposition'].split('filename=')[1]

        // Trigger a click event on the anchor to start the download
        a.click()

        // Cleanup: release the blob URL
        URL.revokeObjectURL(a.href)
      } catch (error) {
      }
      this.isLoading = false
    },
    onReportTypeChange (reportType) {
      this.$emit('report-type-changed', reportType)
    },
    progressWizard () {
      this.isLoading = true
      const progressSlide = () => {
        this.slide++
        this.isLoading = false
      }

      if (this.isOnline) {
        this.$emit('save-for-later', {
          isIntroductionSlide: this.slide === 0,
          isBankInformationSlide: this.slide === 1,
          isContactsSlide: this.slide === 2,
          isBasicInformationSlide: this.slide === 3,
          isReviewSlide: this.slide === 4,
          isFinishSlide: this.slide === 5,
          progressWizard: progressSlide.bind(this)
        })
      } else {
        this.$emit('save-for-later', {
          // isUploadSlide: this.slide === 2,
          isIntroductionSlide: 0,
          isBankInformationSlide: 0,
          isContactsSlide: 0,
          isBasicInformationSlide: 0,
          isReviewSlide: this.slide === 3,
          isFinishSlide: 0,
          progressWizard: progressSlide.bind(this)
        })
      }
    },
    importParticipants (participants) {
      this.$emit('import-participants', participants)
    },
    reset () {
      this.$emit('reset')
    },
    saveForLater () {
      this.$emit('save-for-later', {
        isBankInformationSlide: this.slide === 1,
        isContactsSlide: this.slide === 2,
        isBasicInformationSlide: this.slide === 3,
        isReviewSlide: this.slide === 4,
        isFinishSlide: this.slide === 5,
        progressWizard: () => {
        }
      })
    },
    nextSlide () {
      this.slide++
      this.$emit('next-slide', this.slide)
    },
    previousSlide () {
      this.slide--
      this.$emit('next-slide', this.slide)
    },
    completed (event) {
      const { slide, input } = event
      this.$emit('completd', event)
      this.slides[slide] = input
    }
  }
}
</script>

<style>
.wizard-container {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
}

.wizard-container .wizard-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  gap: .5rem;
  height: 100px;
}

.wizard-container .wizard-actions button {
  padding: .5rem;
  border: none;
  border-radius: 5px;
  background-color: #d1d1d1;
  color: #424242;
  cursor: pointer;
}

.wizard-container .wizard-actions button.is-accent {
  background-color: #7fb942;
  color: white;
}

.wizard-container .wizard-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 100px;
}

.wizard-container .wizard-header .bar {
    width: calc(100% - 40px);
    height: 4px;
    background-color: #929292;
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
}

.wizard-container .wizard-header .slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;
    height: 100px;
    font-weight: 600;
    font-size: .8rem;
}

.wizard-container .wizard-header .slide .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #929292;
    margin-bottom: 5px;
    position: absolute;
    top: 30%;

    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.4rem;
}

.wizard-container .wizard-header .slide .circle[is-active="true"] {
    background-color: #7fb942;
}

.wizard-container .wizard-header .slide .circle[is-completed="true"] {
    background-color: #3e5881;
}

.wizard-slide p {
    margin-bottom: 2rem;
}

.wizard-slide .control input {
    margin-right: 1rem;
    cursor: pointer;
}

.wizard-slide .control label::selection {
    background-color: #7fb942;
    color: white;
}

.wizard-slide .control label {
    cursor: pointer;
}

.wizard-grid {
    display: grid;
    grid-template-columns: 1fr 9fr;
    grid-gap: 1rem;
    margin-bottom: 1rem;
}

.wizard-grid--compact {
    grid-template-columns: 1fr 16fr;
    grid-gap: 1rem;
    margin-bottom: 1rem;
}

.wizard-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wizard-content > div {
  height: 100%!important;
}

.subheading {
  font-size: 1em;
  padding: 30px 0 10px 0;
}

div.flex-field {
  display: inline-block;
}

div.flex-field:not(:first-child) {
  padding-left: 20px;
}

.field {
  color: #4a4a4a;
}

.flex-column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.flex-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap !important;
  gap: 0.5rem;
}

.text-left {
  text-align: left !important;
}

.flex-container .flex-field {
  padding: 0px !important;
  margin: 0px !important;
}

.flex-center {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.flex-left {
  justify-content: flex-start !important;
}

.flex-full {
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-full div {
  width: 100%;
}

.flex-full div div {
  width: 100%;
}

.flex-full div div input {
  width: 100%;
}

.field input,
.field textarea,
.flex-field input,
.column input,
.column textarea {
  font-size: 1em;
  background-color: white;
  border-color: #dbdbdb;
  border-radius: 4px;
  box-shadow: none;
  border-width: 1px;
  border-style: solid;
  min-height: 36px;
  color: #363636;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.375em - 1px);
}

/* Datatable CSS */
.v-datatable-light {
  display: inline-block;
  margin: 10px 0;
}
/* End Datatable CSS */

/* Invite Client CSS*/
.invite-client-wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  /* padding-right: 2.8rem; */
}

.invite-client-wrapper .control > .select {
  display: flex;
  height: 16rem;
}

.annual-questionnaire-form input[disabled=true] {
  opacity: .75!important;
  background: #f0f0f0;
}
/* End Invite Client CSS */
</style>

<style scoped>
#content-area-full {
  background-color: #ebebeb!important;
}
</style>
