<template>
    <div>
      <vue-headful :title="pageTitle" />
      <div v-show="isLoading || questionnaireHash === ''">
        <Loading />
      </div>
      <div v-show="!isLoading" id="body-content-area-all" style="overflow: scroll; margin-top: 9.25rem; padding: 0rem;">
        <annual-questionnaire-wizard
          :reportType="reportType"
          :questionnaire="questionnaire"
          :hasChanged="hasChanged"
          :currentHash="currentHash"
          :participantsHash="participantsHash"
          @report-type-changed="onReportTypeChanged"
          @import-participants="onImportParticipants"
          @save-for-later="saveForLater"
          @reset="getQuestionnaire"
          @finish-annual-questionnaire="finishAnnualQuestionnaire"
        />
      </div>
    </div>
  </template>

<script>
import { mapState } from 'vuex'
import { activeSite } from '../../../vuex-actions'
import { bus } from '../../../main'
import Loading from '../../Loading'
import AnnualQuestionnaireWizard from './AnnualQuestionnaireWizard.vue'
import * as crypto from 'crypto'
import moment from 'moment-timezone'

let pageName = 'Annual Questionnaire'

export default {
  components: {
    Loading,
    AnnualQuestionnaireWizard
  },
  data () {
    return {
      isLoading: true,
      questionnaire: {},
      questionnaireHash: '',
      currentHash: '',
      hasChanged: false,
      reportType: 'online',
      crypto
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return `${pageName} - ${this.activeSite.name}`
    },
    participantsHash () {
      return crypto.createHash('sha256').update(JSON.stringify(this.questionnaire && this.questionnaire.participants ? this.questionnaire.participants : '{ "key": 0}')).digest('hex')
    }
  },
  watch: {
    questionnaire: {
      handler (newVal, oldVal) {
        this.hasChanged = crypto.createHash('sha256').update(JSON.stringify(newVal)).digest('hex') !== this.questionnaireHash
        this.currentHash = crypto.createHash('sha256').update(JSON.stringify(newVal)).digest('hex')
      },
      deep: true
    }
  },
  async mounted () {
    bus.$emit('full-screen-toggle', true)
    let reportType = window.localStorage.getItem('reportType')

    if (reportType && reportType !== 'undefined' && reportType !== 'null') {
      // alert(reportType)
      this.reportType = reportType
    }

    this.getQuestionnaire()
  },
  beforeDestroy () {
    bus.$emit('full-screen-toggle', false)
    this.$router.push('/#/dashboard').then(() => {
      window.location.reload()
    })
  },
  methods: {
    onReportTypeChanged (reportType) {
      // Trigger UI change based on type
      this.reportType = reportType
      window.localStorage.setItem('reportType', reportType)
    },
    finishAnnualQuestionnaire (callback) {
      this.axios
        .post(`/annual-questionnaire/${this.questionnaire.questionnaireId}/complete`)
        .then(() => {
          callback()
        })
        .catch(_ => {
          // console.log(err)
        })
    },
    onImportParticipants (participants) {
      this.isLoading = true
      this.questionnaire.participants = []

      setTimeout(_ => {
        this.questionnaire.participants = participants.map(c => {
          return {
            ...c,
            participantId: parseInt(c.participantId),
            isDeleted: parseInt(c.isDeleted),
            isBCCDeleted: 0,
            questionnaireId: this.questionnaire.questionnaireId
          }
        })

        window.requestAnimationFrame(_ => {
          this.isLoading = false
        })
      }, 1000)
    },
    hashQuestionnaireToDetectDataChanges () {
      this.questionnaireHash = crypto.createHash('sha256').update(JSON.stringify(this.questionnaire)).digest('hex')
    },
    getQuestionnaire () {
      if (this.$route.params.questionnaire) {
        try {
          this.isLoading = true
          this.api().annualQuestionnaire.getAssetReportInBatchDetail({
            batchId: 0,
            reportId: this.$route.params.questionnaire.questionnaireId
          }, (error, response) => {
            if (error) {
              // console.log(error)
            } else {
              this.questionnaire = response
              this.questionnaire.jsonReviewedBy = JSON.parse(this.questionnaire.jsonReviewedBy === '' ? '{}' : this.questionnaire.jsonReviewedBy)
              window.localStorage.setItem(`questionnaire-${this.questionnaire.questionnaireId}`, true)
              this.isLoading = false
              this.hashQuestionnaireToDetectDataChanges()
            }
          })
        } catch (_) {
          // console.log(error)
        } finally {
          this.isLoading = false
        }
      }
    },
    saveForLater (e) {
      let isValid = true
      this.isLoading = true
      let updateStep = null

      if (e.isIntroductionSlide) {
        updateStep = 'introduction'
      }

      // perform validation
      if (e.isBankInformationSlide) {
        updateStep = 'bankInformation'
        if (this.questionnaire.legalAddress === '') {
          isValid = false
        }
      }

      if (e.isContactsSlide) {
        updateStep = 'contacts'
        if (this.questionnaire.contacts.length === 0) {
          isValid = false
        }
      }

      if (e.isBasicInformationSlide) {
        updateStep = 'participants'
        if (this.questionnaire.participants.length === 0) {
          isValid = false
        }
      }

      if (e.isReviewSlide) {
        updateStep = 'review'
      }

      if (!isValid) {
        this.errorToast('Please fill out all required fields')
        this.isLoading = false
        return
      }

      if (this.currentHash === this.questionnaireHash && this.questionnaire.jsonReviewedBy && this.questionnaire.jsonReviewedBy[updateStep]) {
        e.progressWizard()
        this.isLoading = false
        return
      }

      this.axios
        .patch(
          `annual-questionnaire/0/reports/${this.questionnaire.questionnaireId}`,
          {
            ...this.questionnaire,
            jsonReviewedBy: JSON.stringify(this.questionnaire.jsonReviewedBy),
            participants: this.questionnaire.participants.map(p => {
              return {
                ...p,
                terminationDate: p.terminationDate && p.terminationDate !== '' ? moment(p.terminationDate).format('YYYY-MM-DD') : ''
              }
            }),
            updateStep
          }
        )
        .then(async (response) => {
          if (response.status === 200) {
            // this.successToast('Successfully saved Questionnaire!')
            this.getQuestionnaire()
            e.progressWizard()
          }
        })
        .catch((error) => {
          this.handleApiErr(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

  <style scoped>

  #pagelayout {
    width: 100%;
  }

  #body-content-area-all {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-y: hidden;
    height: calc(100vh - 9.25rem - 62px);
  }

  #body-content-area-all > div {
    height: 100%;
  }
  .third {
    width:50%;
    padding-bottom: 25px;
  }

  .twothird {
    width: 50%;
    padding-top: 25px;
    padding-left: 25px;
  }

  .custom-container {
    padding: 0px!important;
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  #app-body-area[activelayout="osr-facing"] #body-content-area-all, #app-body-area[activelayout="administrative"] #body-content-area-all, #app-body-area[activelayout="client-facing"] #body-content-area-all {
    margin: 0!important;
    width: 100%;
    padding: 1rem;
    height: calc(100vh - 170px);
  }

  .card {
    height: 100%;
  }

  @media (min-width: 1201px) and (max-width: 1500px) {
    .third {
      width: 50%!important;
    }

    .twothird {
      width: 50%!important;
    }
  }

  @media (min-width: 751px) and (max-width: 1201px) {
    .third {
      width: 40%!important;
    }

    .twothird {
      width: 60%!important;
    }
  }

  @media (max-width: 750px) {
    .third {
      width: 100%!important;
    }

    .twothird {
      width: 100%!important;
      padding-left: 0!important;
      padding-top: 10px;
      padding-bottom: 25px;
    }
  }
  </style>
