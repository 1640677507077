<template>
  <div
    style="
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    "
  >
    <div class="wizard-grid">
      <div
        class="wizard-grid-item"
        style="max-width: 200px; justify-content: flex-start"
      >
        <b>Step 1:</b>
      </div>
      <div class="wizard-grid-item">
        <b>Download Questionnaire</b>
        <p style="padding-top: 0.5rem">
          Click the <strong>Download</strong> button to Download the
          Questionnaire.
        </p>
      </div>
      <div class="wizard-grid-item">
        <div class="wizard-actions" style="padding-top: 1.5rem">
          <button @click="handleDownload">Download</button>
        </div>
      </div>
    </div>
    <div class="wizard-grid" style="height: 90px;">
      <div
        class="wizard-grid-item"
        style="justify-content: flex-start"
      >
        <b>Step 2:</b>
      </div>
      <div class="wizard-grid-item">
        <b>Complete Questionnaire</b>
        <p>
          Complete the Questionnaire using Microsoft Excel on your local
          computer.
        </p>
        <br />
      </div>
      <div class="wizard-grid-item" style="width: 150px;"></div>
    </div>
    <div class="wizard-grid">
      <div
        class="wizard-grid-item"
        style="max-width: 200px; justify-content: flex-start"
      >
        <b>Step 3:</b>
      </div>
      <div class="wizard-grid-item">
        <b>Upload Questionnaire</b>
        <p style="padding-top: 0.5rem">
          Click the <strong>Upload</strong> button to submit your Questionnaire
          response. <br /><br />
          or
          <br /><br />
          E-mail your completed Questionnaire to BCCclientservices@bcc-usa.com.
        </p>
      </div>
      <div class="wizard-grid-item">
        <div class="wizard-actions" style="padding-top: 1.5rem">
          <button :disabled="!hasDownloaded" @click="upload">Upload</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadSlide',
  props: {
    questionnaire: {
      type: Object,
      required: true
    },
    upload: {
      type: Function,
      required: true
    },
    download: {
      type: Function,
      required: true
    },
    hasUploaded: {
      type: Boolean,
      required: true
    }
  },
  data: function () {
    return {
      secondsUntilRedirect: 10,
      interval: null,
      hasDownloadedExport: false
    }
  },
  computed: {
    hasDownloaded () {
      return this.hasDownloadedExport
    }
  },
  created () {
    if (!this.hasUploaded) {
      this.$emit('toggleLock', {})
    }
  },
  mounted () {
    let hasDownloaded = localStorage.getItem(
      '[' + this.questionnaire.questionnaireId + ']hasDownloaded'
    )

    if (hasDownloaded !== undefined) {
      this.hasDownloadedExport = hasDownloaded
    }
  },
  methods: {
    handleDownload () {
      this.hasDownloadedExport = true
      localStorage.setItem(
        '[' + this.questionnaire.questionnaireId + ']hasDownloaded',
        true
      )
      this.download()
    }
  }
}
</script>

<style scoped>
.wizard-grid {
  width: 100%;
  grid-template-columns: 1fr 8fr 1fr;
}

.wizard-grid .wizard-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.wizard-grid .wizard-actions button {
  width: 150px;
  background-color: #7fb942;
  color: white;
}
</style>
