<template>
  <div
    class="columns"
    style="
      width: 100%;
      padding: 0px !important;
      overflow: scroll !important;
    "
  >
    <div
      v-if="!isLoading"
      class="column"
      style="width: 100%; padding: 0px !important"
    >
      <div
        class="subheading"
        style="
          padding: 0px 0px 7px 0px;
          margin-bottom: 1.5rem;
          border-bottom: solid 1px lightgray;
          position: relative;
        "
      >
        <b>Contact Information</b>
        <div class="show-deleted">
          <button
            v-if="hasDeletedContacts"
            @click="showDeletedContacts = !showDeletedContacts"
            type="button"
          >
            {{ showDeletedContacts ? "Hide" : "Show" }} Deleted Contacts
          </button>
          <button
            @click="resetContactTable()"
            class="button is-warning is-small"
            type="button"
            style="margin-left: 1rem"
          >
            Reset Contacts
          </button>
        </div>
        <br />
        Please review and correct the information as needed.
      </div>
      <div
        class="columns"
        style="width: 100%; padding: 0px 0px 7px 0px !important; gap: 0.5rem"
      >
        <div class="column">
          <p
          v-if="
            formErrors && (formErrors.name || formErrors.title || formErrors.phone || formErrors.email)
          "
        >
        One or more contacts have issues with the following:

          <ul style="color: red">
            <li v-if="formErrors.name">{{ formErrors.name }}</li>
            <li v-if="formErrors.title">{{ formErrors.title }}</li>
            <li v-if="formErrors.phone">{{ formErrors.phone }}</li>
            <li v-if="formErrors.email">{{ formErrors.email }}</li>
          </ul>
        </p>
          <div class="table">
            <DataTable
              :header-fields="contactFields"
              :tableData="filteredContacts"
              :is-loading="isLoading"
              :css="datatableCss"
              :limit="100"
              not-found-msg="No contacts found"
              @on-add-row="addContactRow"
            >
              <div slot="actions" slot-scope="props">
                <a
                  v-if="props && props.rowData && props.rowData.isDeleted === 0"
                  class="button is-light"
                  @click="
                    props.rowData.contactId && props.rowData.contactId != 0
                      ? (props.rowData.isDeleted = 1)
                      : removeRow(
                          questionnaire.contacts,
                          props,
                          'questionnaire.contacts'
                        )
                  "
                  style="max-width: 50px"
                  >-</a
                >
                <a
                  v-if="props && props.rowData && props.rowData.isDeleted === 1"
                  class="button is-light"
                  @click="props.rowData.isDeleted = 0"
                  style="max-width: 50px"
                  >+</a
                >
              </div>
            </DataTable>
            <br />
          </div>
        </div>
      </div>
      <div style="width: 100%; margin-top: 3rem">
        <div style="display: flex; justify-content: flex-start; align-items: center;">
          <input
            type="checkbox"
            name="confirmed"
            v-model="hasReviewed"
            :disabled="!isValid"
            style="margin-right: 0.75rem"
          />
          <label for="confirmed" @click="!isValid ? null : hasReviewed = !hasReviewed" :disabled="!isValid"
            >I have reviewed the above information.</label
          >
        </div>
        <p :disabled="!isValid"
          v-if="
            questionnaire.jsonReviewedBy &&
            questionnaire.jsonReviewedBy.contacts
          "
          style="color: red"
        >
          {{ questionnaire.jsonReviewedBy.contacts }}
        </p>
      </div>
    </div>
    <div v-else>
      <Loading />
    </div>
  </div>
</template>

<script>
import DataTable from '../../../templates/DataTable'
import Loading from '../../../Loading'

export default {
  name: 'BankInformationSlide',
  components: {
    DataTable,
    Loading
  },
  props: {
    questionnaire: {
      type: Object,
      required: true
    },
    hash: {
      type: String,
      required: true
    }
  },
  watch: {
    hash: {
      handler: function (val, oldVal) {
        try {
          this.GetBankInformation()
          if (val !== oldVal) {
            this.hasReviewed = ''
            this.validateInput()
            if (this.isValid) {
              this.hasReviewed = this.questionnaire.jsonReviewedBy && this.questionnaire.jsonReviewedBy.contacts
            } else {
              this.hasReviewed = ''
              this.$forceUpdate()
            }
          }
        } catch (_) {
          // console.log(error)
        }
      },
      deep: true
    },
    hasReviewed: function (val, oldVal) {
      this.$emit('has-reviewed', val)
    }
  },
  data: function () {
    return {
      isLoading: true,
      isValid: true,
      hasReviewed: false,
      showDeletedContacts: false,
      formErrors: {},
      contactFields: [
        {
          name: 'name',
          label: 'Name',
          width: '25%',
          isEditable: true
        },
        {
          name: 'title',
          label: 'Title',
          width: '25%',
          isEditable: true
        },
        {
          name: 'phone',
          label: 'Direct Phone',
          format: 'phone',
          width: '25%',
          isEditable: true
        },
        {
          name: 'email',
          label: 'Email',
          width: '25%',
          isEditable: true
        },
        '__slot:actions'
      ],
      datatableCss: {
        table: 'table table-bordered table-hover table-striped table-center',
        th: 'header-item',
        thWrapper: 'th-wrapper',
        footer: 'footer'
      }
    }
  },
  mounted () {
    this.$emit('rendered', this)
    this.GetBankInformation()
    this.validateInput()
  },
  computed: {
    hasDeletedContacts () {
      return this.questionnaire.contacts &&
        this.questionnaire.contacts.length > 0
        ? this.questionnaire.contacts.filter((c) => c.isDeleted).length > 0
        : false
    },
    filteredContacts () {
      return this.questionnaire.contacts &&
        this.questionnaire.contacts.length > 0
        ? this.showDeletedContacts
          ? this.questionnaire.contacts
          : this.questionnaire.contacts.filter((c) => !c.isDeleted)
        : []
    }
  },
  methods: {
    validateInput () {
      let contactsCountIsAboveZero = this.questionnaire.contacts.filter(c => !c.isDeleted).length > 0

      const fields = {
        'name': {
          type: 'string',
          required: true
        },
        'title': {
          type: 'string',
          required: true
        },
        'phone': {
          type: 'string',
          required: true
        },
        'email': {
          type: 'email',
          required: true
        }
      }

      let contactsAreValid = true
      this.formErrors = {}

      this.questionnaire.contacts.forEach(contact => {
        Object.keys(fields).forEach(field => {
          let requirements = fields[field]

          if ((requirements.type === 'string' && typeof contact[field] !== 'string') || (contact[field] === '')) {
            contactsAreValid = false
            this.formErrors[field] = field + ' isn\'t valid'
          }

          // Phone format = (385) 223-9660
          if ((requirements.type === 'phone' && contact[field] && contact[field] !== '') || (requirements.type === 'phone' && (contact[field] === '' || !contact[field]))) {
            contactsAreValid = false
            this.formErrors[field] = field + ' number is incomplete or missing'
          }

          // email format is = email@domain
          if (requirements.type === 'email' && contact[field] && !contact[field].match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            contactsAreValid = false
            this.formErrors[field] = field + ' is incomplete or missing'
          }
        })
      })

      this.isValid = contactsCountIsAboveZero && contactsAreValid
      if (this.isValid) {
        this.hasReviewed = this.questionnaire.jsonReviewedBy && this.questionnaire.jsonReviewedBy.contacts
      } else {
        this.hasReviewed = ''
      }
    },
    GetBankInformation () {
      this.input = this.questionnaire
      this.hasReviewed = this.questionnaire.jsonReviewedBy && this.questionnaire.jsonReviewedBy.contacts
      this.isLoading = false
    },
    resetContactTable () {
      this.isLoading = true
      this.$emit('reset')
    },
    addContactRow () {
      this.questionnaire.contacts.push({
        name: '',
        title: '',
        email: '',
        phone: '',
        isDeleted: 0
      })
    },
    removeRow (array, props, arrayName) {
      array.splice(props.rowIndex, 1)
      this.$forceUpdate()
    }
  }
}
</script>

<style>
.columns {
  width: 100% !important;
  margin: 0px !important;
}
</style>

<style scoped>
.column {
  padding: 0.5rem !important;
  margin: 0rem !important;
}

.show-deleted {
  position: absolute;
  right: 0;
  top: calc(50% - 1rem);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.show-deleted button:not(.button),
.show-deleted a:not(.button) {
  background: transparent;
  border: 0px;
  cursor: pointer;
}

.show-deleted button:not(.button):hover,
.show-deleted a:not(.button):hover {
  text-decoration: underline;
}

.table {
  background-color: transparent;
}
</style>
