<template>
  <div class="wizard-slide">
    <p>
      {{ questionnaire.portalMessage }}
    </p>
    <br />
    <div class="wizard-grid">
      <div class="wizard-grid-item" style="max-width: 200px">
        <b>Instructions</b>
      </div>
      <div class="wizard-grid-item">
        <p>
          Please review and complete the Annual Questionnaire in its entirety.
          The information you provide should be as of {{ formattedAsOfDate }}. The
          completed Questionnaire should be completed and returned no later than
          {{ formatteDueDate }}.
        </p>
        <p>
          <b> Before you begin please consider gathering the following:</b>
        </p>
        <p>
          Plan participants and all insureds contact information, such as contact number and email, retirement or termination status, and salary and compensation information.
        </p>
        <br>
        <br>
        <div class="control">
            <input type="radio" name="process" value="online" v-model="processType">
            <label for="process" @click="processType = 'online'">Complete Questionnaire online</label>
        </div>
        <p style="padding: 1rem; padding-left: 2rem; margin-bottom: 0rem">
            <b style="margin-right: .5rem;">Note:</b> You will have the option to Save For Later once you begin the process. The Questionnaire does not have to be completed all in one sitting.
        </p>
        <div class="control">
            <input type="radio" name="process" value="offline" v-model="processType">
            <label for="process" @click="processType = 'offline'">Download Questionnaire to complete manually</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data: function () {
    return {
      processType: 'online'
    }
  },
  props: {
    questionnaire: {
      type: Object,
      required: true
    },
    reportType: {
      type: String,
      required: true
    }
  },
  watch: {
    processType: {
      handler (newVal, oldVal) {
        this.$emit('process-type-changed', newVal)
      }
    }
  },
  computed: {
    year: function () {
      let reportDate = this.questionnaire.reportingPeriod
      if (reportDate) {
        return parseInt(reportDate.split('-')[0])
      }

      return parseInt(new Date().getFullYear())
    },
    formattedAsOfDate: function () {
      return moment(this.questionnaire.asOfDate).format('MMMM D, YYYY')
    },
    formatteDueDate: function () {
      return moment(this.questionnaire.dueDate).format('MMMM D, YYYY')
    }
  },
  async mounted () {
    // let reportType = await window.localStorage.getItem('reportType')
    this.processType = this.reportType
    this.$emit('rendered', this)
  },
  methods: {
  }
}
</script>
