<template>
<div>
  <div class="columns" style="width: 100%; padding: 0px!important;">
    <div class="column" style="width: 100%; padding: 0px!important;">
      <div
        class="subheading"
        style="
          padding: 0px 0px 7px 0px;
          margin-bottom: 1.5rem;
          border-bottom: solid 1px lightgray;
        "
      >
        <b>Bank Contact Information</b>
        <br />
        Please review and correct the information as needed.
      </div>
      <div class="columns" style="width: 100%; padding:  0px 0px 7px 0px!important; gap: .5rem;">
        <div
          class="column flex-container flex-full"
          style="
            flex-wrap: wrap;
            display: flex !important;
            flex-direction: row!important;
            gap: 0.5rem !important;
            padding: 0px;
          "
        >
          <div class="field" style="width: 100%">
            <label class="label text-left is-size-7">
              Legal Address
              <span class="has-text-warning" style="padding-left: 3px">*</span>
            </label>
            <div class="control-center" style="width: 100%">
              <input
                style="width: 100%"
                v-model="input.clientAddress"
                name="legal address"
                validator-requirement="'required'"
              />
              <span
                v-show="validationErrors['clientAddress']"
                class="help is-danger"
                >{{ validationErrors['clientAddress'] }}</span
              >
            </div>
          </div>
          <div class="field" style="max-width: calc(50%) !important">
            <label class="label text-left is-size-7">
              City
              <span class="has-text-warning" style="padding-left: 3px">*</span>
            </label>
            <div class="control-center" style="width: 100%">
              <input
                style="width: 100%"
                v-model="input.clientAddressCity"
                name="legal address"
                validator-requirement="'required'"
              />
              <span
                v-show="validationErrors['clientAddressCity']"
                class="help is-danger"
                >{{ validationErrors['clientAddressCity'] }}</span>
            </div>
          </div>
          <div class="field" style="max-width: calc(15%) !important">
            <label class="label text-left is-size-7">
              ST
              <span class="has-text-warning" style="padding-left: 3px">*</span>
            </label>
            <div class="control-center" style="width: 100%">
              <input
                style="width: 100%"
                v-model="input.clientAddressState"
                name="legal address"
                validator-requirement="'required'"
              />
              <span
                v-show="validationErrors['clientAddressState']"
                class="help is-danger"
                >{{ validationErrors['clientAddressState'] }}</span>
            </div>
          </div>
          <div class="field" style="max-width: calc(30%) !important">
            <label class="label text-left is-size-7">
              Postal Code
              <span class="has-text-warning" style="padding-left: 3px">*</span>
            </label>
            <div class="control-center" style="width: 100%">
              <input
                style="width: 100%"
                v-model="input.clientAddressPostal"
                name="legal address"
                validator-requirement="'required'"
              />
              <span
                v-show="validationErrors['clientAddressPostal']"
                class="help is-danger"
                >{{ validationErrors['clientAddressPostal'] }}</span>
            </div>
          </div>
        </div>
        <div
          class="column flex-container flex-full"
          style="
            flex-wrap: wrap;
            flex-direction: row!important;
            display: flex;
            gap: 0.5rem !important;
            padding: 0px;
          "
        >
          <div class="field" style="width: 100%">
            <label class="label text-left is-size-7">
              Mailing Address
              <span class="has-text-warning" style="padding-left: 3px">*</span>
            </label>
            <div class="control-center" style="width: 100%">
              <input
                style="width: 100%"
                v-model="input.clientMailingAddress"
                name="legal address"
                validator-requirement="'required'"
              />
              <span
                v-show="validationErrors['clientMailingAddress']"
                class="help is-danger"
                >{{ validationErrors['clientMailingAddress'] }}</span>
            </div>
          </div>
          <div class="field" style="max-width: calc(50%) !important">
            <label class="label text-left is-size-7">
              City
              <span class="has-text-warning" style="padding-left: 3px">*</span>
            </label>
            <div class="control-center" style="width: 100%">
              <input
                style="width: 100%"
                v-model="input.clientMailingAddressCity"
                name="legal address"
                validator-requirement="'required'"
              />
              <span
                v-show="validationErrors['clientMailingAddressCity']"
                class="help is-danger"
                >{{ validationErrors['clientMailingAddressCity'] }}</span>
            </div>
          </div>
          <div class="field" style="max-width: calc(15%) !important">
            <label class="label text-left is-size-7">
              ST
              <span class="has-text-warning" style="padding-left: 3px">*</span>
            </label>
            <div class="control-center" style="width: 100%">
              <input
                style="width: 100%"
                v-model="input.clientMailingAddressState"
                name="legal address"
                validator-requirement="'required'"
              />
              <span
                v-show="validationErrors['clientMailingAddressState']"
                class="help is-danger"
                >{{ validationErrors['clientMailingAddressState'] }}</span>
            </div>
          </div>
          <div class="field" style="max-width: calc(30%) !important">
            <label class="label text-left is-size-7">
              Postal Code
              <span class="has-text-warning" style="padding-left: 3px">*</span>
            </label>
            <div class="control-center" style="width: 100%">
              <input
                style="width: 100%"
                v-model="input.clientMailingAddressPostal"
                name="legal address"
                validator-requirement="'required'"
              />
              <span
                v-show="validationErrors['clientMailingAddressPostal']"
                class="help is-danger"
                >{{ validationErrors['clientMailingAddressPostal'] }}</span>
            </div>
          </div>
        </div>
        <div
          class="column flex-container flex-full"
          style="
            flex-wrap: wrap;
            gap: 0.5rem !important;
            padding: 0px;
          "
        >
          <div
            class="field"
            style="width: 100%; padding: 0 !important; margin: 0px !important"
          >
            <label class="label text-left is-size-7">
              Main Phone
              <span class="has-text-warning" style="padding-left: 3px">*</span>
            </label>
            <div class="control-center" style="width: 100%; ">
              <input
              style="width: 100%; "
                v-model="input.clientPhone"
                name="business phone"
                validator-requirement="'required'"
              />
              <span v-show="errors.has('business phone')" class="help is-danger">{{
                errors.first("business phone")
              }}</span>
            </div>
          </div>
          <div class="field" style="width: 100%; margin-top: .6rem">
            <label class="label text-left is-size-7">
              Main Fax
              <span class="has-text-warning" style="padding-left: 3px">*</span>
            </label>
            <div class="control-center" style="width: 100%; ">
              <input
              style="width: 100%; "
                v-model="input.clientFax"
                name="fax number"
                validator-requirement="'required'"
              />
              <span v-show="errors.has('fax number')" class="help is-danger">{{
                errors.first("fax number")
              }}</span>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
  <div style="width: 100%; margin-top: 3rem;">
    <input type="checkbox" name="confirmed" v-model="hasReviewed" style="margin-right: .75rem;" :disabled="!inputsAreValid">
    <label for="confirmed" @click="inputsAreValid ? hasReviewed = !hasReviewed : null"  :disabled="!inputsAreValid">I have reviewed the above information.</label>
    <p v-if="questionnaire.jsonReviewedBy && questionnaire.jsonReviewedBy.bankInformation" style="color: red;" :disabled="!inputsAreValid">
      {{ questionnaire.jsonReviewedBy.bankInformation }}
    </p>
  </div>
</div>
</template>

<script>
export default {
  name: 'BankInformationSlide',
  props: {
    questionnaire: {
      type: Object,
      required: true
    },
    parent: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: true
    }
  },
  watch: {
    hasReviewed: function (val, oldVal) {
      this.$emit('has-reviewed', val)
    },
    questionnaire: {
      handler: function (val, oldVal) {
        this.hasReviewed = false
        this.validateInput()
      },
      deep: true
    }
  },
  data: function () {
    return {
      hasReviewed: false,
      inputsAreValid: true,
      input: {
        clientAddress: '',
        clientAddressCity: '',
        clientAddressState: '',
        clientAddressPostal: '',
        clientMailingAddress: '',
        clientMailingAddressCity: '',
        clientMailingAddressState: '',
        clientMailingAddressPostal: '',
        clientPhone: '',
        clientFax: ''
      },
      validationErrors: {

      }
    }
  },
  mounted () {
    this.$emit('rendered', this)
    this.GetBankInformation()
  },
  methods: {
    validateInput () {
      let isValid = true
      this.validationErrors = {}

      let fields = [
        'clientAddress',
        'clientAddressCity',
        'clientAddressState',
        'clientAddressPostal',
        'clientMailingAddress',
        'clientMailingAddressCity',
        'clientMailingAddressState',
        'clientMailingAddressPostal',
        'clientPhone',
        'clientFax'
      ]

      fields.forEach(field => {
        if (this.questionnaire[field].trim() === '') {
          isValid = false
          this.validationErrors[field] = 'This field is required'
        }
      })

      if (isValid) {
        this.inputsAreValid = true
        this.hasReviewed = this.questionnaire.jsonReviewedBy && this.questionnaire.jsonReviewedBy.bankInformation
      } else {
        this.inputsAreValid = false
      }
    },
    GetBankInformation () {
      this.input = this.questionnaire
      this.hasReviewed = this.questionnaire.jsonReviewedBy && this.questionnaire.jsonReviewedBy.bankInformation
    }
  }
}
</script>

<style>
.columns {
  width: 100% !important;
  margin: 0px !important;
}
</style>
